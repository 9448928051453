import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Container, IconButton, Divider } from '@mui/material';
import { Email, Facebook, Instagram, LinkedIn, LocalPhone, LocationOn, Twitter } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Images from 'assets/Images';
import colors from 'app/style/colors';
import { CurrentYear } from 'app/utils';

const useStyle = makeStyles({
  navItems: {
    cursor: 'pointer',
    padding: '8px 0px',
    color: colors.textPrimary
  },
})

function Footer() {

  const classes = useStyle();

  return (
    <Fragment>

      <Box sx={{ py: 7 }}>
        <Container>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item md={4}>
              <Link to="/">
                <Typography
                  component="img"
                  src={Images.logo}
                  alt="Mashr"
                  sx={{
                    height: 50,
                    mr: 2
                  }}
                />
              </Link>
              <Typography variant="body2" sx={{ mt: 1, mb: 2.5, color: colors.textPrimary }} >We Help you set up & grow a business with infinite opportunities in the top E-commerce marketplaces</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6" sx={{ pt: 3, fontWeight: 700, fontFamily: 'Barlow' }} >Social</Typography>
              <Box sx={{ py: 2 }}>
                <Typography component="a" href="https://www.facebook.com/mashrco/" target="_blank" >
                  <IconButton sx={{ mr: 1 }}>
                    <Facebook color="primary" />
                  </IconButton></Typography>
                <Typography component="a" href="https://www.instagram.com/accounts/login/?next=/mashrco/" target="_blank" >
                  <IconButton sx={{ mr: 1 }}>
                    <Instagram color="primary" />
                  </IconButton>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5}>
              <Typography variant="h6" sx={{ pt: 3, fontWeight: 700, fontFamily: 'Barlow' }} >Contact Us</Typography>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                  <Email sx={{ fontSize: '25px', color: colors.primary }} />
                  <Typography sx={{ pl: 1, pr: 1 }}>Info@mashr.us</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                  <LocalPhone sx={{ fontSize: '25px', color: colors.primary }} />
                  <Typography sx={{ pl: 1, pr: 1 }}>+923213427382</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                  <LocationOn sx={{ fontSize: '25px', color: colors.primary }} />
                  <Typography sx={{ pl: 1, pr: 1 }}>Pakistan</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Divider />

      <Box sx={{ py: 1.5 }}>
        <Container>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item md={6}>
              <Typography variant="body2" sx={{ my: 1, color: colors.textPrimary }} >© <CurrentYear />. All rights reserved</Typography>
            </Grid>
            <Grid item md={6} sx={{ textAlign: 'right' }} >
              <Typography variant="body2" component="span" sx={{ my: 1, mx: 1, color: colors.textPrimary }} >Help Center</Typography>
              <Typography variant="body2" component="span" sx={{ my: 1, mx: 1, color: colors.textPrimary }} >Term of Service</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Fragment>
  );
}

export default Footer;