import React, { Fragment } from 'react'

// *Import Components
import Footer from 'app/components/Footer/Footer'

function Layout({ children }) {
  return (
    <Fragment>

      {/* ========== Header ========== */}
      {/* <Header /> */}

      {children}

      <Footer />
    </Fragment>
  )
}

export default Layout