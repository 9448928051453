const Images = {
  // *Logo
  logo: require('./Images/mashr.png'),
  logo2: require('./Images/mashr2.png'),
  learningSectionBanner: require('./Images/learning.jpg'),
  learningSectionDetail: require('./Images/learning-section-detail.jpg'),
  trainingCourse: require('./Images/training-course-1.jpg'),
  lowerTrainingCourse: require('./Images/lower-training-course-1.jpg'),
  registration: require('./Images/register.jpg'),
  learningAnimate: require('./Images/learningAnimate.gif'),
  hero: require('./Images/hero-bg-1.jpg'),
  heroBG: require('./Images/bannerImg.jpg'),
  digital1: require('./Images/dm1.png'),
  digital2: require('./Images/dm2.png'),
  freelancer: require('./Images/freelancer.png'),
  label: require('./Images/label.png'),
  wholesale: require('./Images/wholesale.png'),
  shopify: require('./Images/shopify.png'),
  mobile: require('./Images/Image-1.png'),


}

export default Images;

export const Loader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="224px" height="224px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path fill="none" stroke="#fa541c" stroke-width="8" stroke-dasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round" >
        <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1.4492753623188404s" keyTimes="0;1" values="0;256.58892822265625" />
      </path>
    </svg>
  )
}

export const Banner = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1211 370" >
      <path fill="#fff" d="M0,318c0,2,6,5.9,7.5,6.9c34.3,22.6,70.4,4.6,99.9-16.1c58.4-40.9,132.8-26.7,198-14.7  c22.1,4.1,44.8,8.8,67.2,4.6c31.5-5.9,55-31.3,78-51.4c26.7-23.4,56.3-45.1,87-62.8c12.9-7.4,26.2-14,40.1-19.3  c75.8-29,152.8,1.9,227.6,18.9c4,0.9,8,1.8,11.9,2.6c85.3,18.5,132.7-45,185.5-99.5c29.2-30.1,66.6-51.8,111.2-65.5  c22.1-6.8,45-11.1,68.6-12.7c9.5-0.7,19-1.3,28.5-1.9c0,120.9,0,241.9,0,362.9c-403.8,0-807.5,0-1211.2,0C0,370,0,318,0,318z" />
    </svg>
  )
}


