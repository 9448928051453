import React, { useEffect, useState } from 'react';
import { DoubleArrowOutlined, LockOpen } from '@mui/icons-material'
import { Box, Button, CardMedia, Container, Divider, Grid, Typography } from '@mui/material'

//Import Components
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'
import Register from 'app/views/Session/Register/Register';
import { Link, useParams } from 'react-router-dom';
import { Service } from 'app/config/service';

function Training() {

  const { id } = useParams();

  const courseIncludes = [
    'Develop your own FBA wholesale business',
    'How to provide VA services for FBA wholesale',
    'What benefits to selling and how to earn freelancing income from FBA WHOLESALE?',
    'How to prepare FBA wholesale proposals attendees skill analysis',
    'Training feedback',
    'Post-training Q&A to help with practical issues',
  ];

  const [openRegister, setOpenRegister] = useState(false);

  // *For Training Detail 
  const [detail, setDetail] = useState({});

  const getTrainingDetail = async () => {
    try {
      const { Data } = await Service.getTrainingDetail(id)
      setDetail(Data)
    } catch (error) {
      console.log('file: Training.js => line 34 => getTrainingDetail => error', error)
    }
  }

  useEffect(() => {
    getTrainingDetail()
  }, [id]);

  return (

    <Layout>

      {/* Register Section  */}
      <Register openRegister={openRegister} handleRegisterDialog={() => setOpenRegister(!openRegister)} courseId={id} />
      {/*End Register Section */}
      {/* ========== Service Banner ========== */}
      <Container>
        <Box
          sx={{
            textAlign: 'center',
            marginTop: 1.2, borderRadius: '14px',
            background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${detail.InsideImage})`, backgroundPosition: 'center',
            height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }}
        >
          <Link to="/">
            <Typography
              component="img"
              src={Images.logo2}
              alt="Mashr"
              sx={{
                height: 50,
                mr: 2
              }}
            />
          </Link>
          <Typography variant="h2" sx={{
            color: colors.white, marginTop: '50px', fontWeight: 'bold'
          }}>
            {detail.CourseName}
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>
        <Grid sx={{ mt: 6 }}>
          <Box>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Program Overview
            </Typography>
            <Typography variant="h6">
              {detail.BriefOverview}
            </Typography>
          </Box>
          {/* <Box sx={{ mt: 4 }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Course Name
            </Typography>
            <Typography variant="h6">
              {detail.CourseName}
            </Typography>
            {/* <Typography variant="h6" sx={{ textAlign: 'left', my: 2, color: colors.textPrimary }}>
              {courseIncludes.map((item, index) => (
                <li key={index} style={{ listStyleType: "none", display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                  <DoubleArrowOutlined style={{ color: colors.primary, paddingRight: '12px' }} />
                  {item}
                </li>
              ))}
            </Typography> */}
          {/* </Box>  */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Course Outline
            </Typography>
            <Typography variant="h6">
              {detail.CourseOutline}
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              What You Will Get
            </Typography>
            <Typography variant="h6">
              {detail.WYWGet}
            </Typography>
          </Box>
          {/* <Box sx={{ mt: 4 }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Schedule
            </Typography>
            <Typography variant="h6">
              {detail.Schedule}
            </Typography>
          </Box> */}
        </Grid>
      </Container>
      {/* ========== Service Banner ========== */}
      <Box sx={{
        marginTop: 10,
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.lowerTrainingCourse})`,
        height: 155, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
      }
      }>

        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{
            color: colors.white, marginTop: '15px', fontWeight: 'bold'
          }}>
            So what are you waiting for..?
          </Typography>
          <Typography variant="h4" sx={{
            color: colors.primary,
          }}>
            Get Yourself registered for the course
          </Typography>
          <Button variant="contained"
            endIcon={<LockOpen />}
            onClick={() => setOpenRegister(!openRegister)}
            sx={{ color: colors.white, my: 2 }}>Register</Button>
        </Grid>

      </Box>
    </Layout>
  )
}

export default Training