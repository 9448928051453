import React, { Fragment, useEffect, useState } from 'react';
import { LockOpen, ArrowForward, Facebook, Instagram } from '@mui/icons-material';
import { Box, Card, Button, CardContent, Grid, Typography, Container, Tabs, Tab, IconButton, CardMedia } from '@mui/material';
import clsx from 'clsx';
import Register from '../Session/Register/Register';
import colors from 'app/style/colors';
import Images, { Banner, Loader } from 'assets/Images';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Service } from 'app/config/service';
import Layout from 'app/layout/Layout';

const useStyle = makeStyles({
  tabButton: {
    fontSize: '18px !important',
    cursor: 'pointer',
    color: colors.black,
    '&:hover': {
      color: colors.primary,
      borderBottom: `2px solid ${colors.primary}`
    }
  },
  active: {
    color: colors.primary,
    borderBottom: `2px solid ${colors.primary}`
  }
})


function Learning() {

  const classes = useStyle();

  const [openRegister, setOpenRegister] = useState(false);

  const [isActive, setIsActive] = useState(null);

  // *For Set Course Id
  const [courseId, setCourseId] = useState(null);

  // *For Trainings
  const [trainings, setTrainings] = useState([]);
  const [category, setCategory] = useState([]);

  const [loader, setLoader] = useState(true);

  const getTrainings = async () => {
    try {
      const limit = 10
      const page = 1
      const { Data } = await Service.getTraining(limit, page)
      setTrainings(Data)
      setIsActive(null)
    } catch (error) {
      console.log('file: Learning.js => line 30 => getTrainings => error', error)
    }
  }

  const getCategory = async () => {
    try {
      const { Data } = await Service.getCategory()
      setCategory(Data)
      setLoader(false)
    } catch (error) {
      console.log('file: Learning.js => line 42 => getCategory => error', error)
    }
  }

  const getTrainingsById = async (id) => {
    try {
      const limit = 10
      const page = 1
      const { Data } = await Service.getTrainingsById(limit, page, id)
      setTrainings(Data)
    } catch (error) {
      console.log('file: Learning.js => line 42 => getCategory => error', error)
    }
  }

  useEffect(() => {
    getTrainings()
    getCategory()
  }, []);

  //* Scroll Tab
  const [scrollValue, setScrollValue] = useState(0);

  const handleScroll = (event, newValue) => {
    setScrollValue(newValue);
  };

  return (
    <Layout>

      {/* Register Section  */}
      <Register openRegister={openRegister} handleRegisterDialog={() => setOpenRegister(!openRegister)} courseId={courseId} />
      {/*End Register Section */}

      {/* ========== Service Banner ========== */}

      <Box
        sx={{
          position: "relative",
          background: `linear-gradient( to left top,rgba(250, 84, 28), rgba(250, 84, 28,0.8)),url(${Images.heroBG})no-repeat center center / cover`,
        }}>


        <Box sx={{ py: 2 }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>

            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Link to="/" >
                <Typography
                  component="img"
                  src={Images.logo2}
                  alt="Mashr"
                  sx={{
                    height: 70,
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <Box sx={{ mr: 2 }}>
                <Typography component="a" href="https://www.facebook.com/mashrco/" target="_blank" >
                  <IconButton>
                    <Facebook sx={{ color: "white" }} />
                  </IconButton></Typography>
                <Typography component="a" href="https://www.instagram.com/accounts/login/?next=/mashrco/" target="_blank" >
                  <IconButton>
                    <Instagram sx={{ color: "white" }} />
                  </IconButton></Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>


        <Box sx={{ position: "absolute", bottom: { xs: -8, md: -7 }, left: 0, width: "100%", zIndex: 1 }}>
          <Banner />
        </Box>

        <Container>
          <Grid container spacing={4}  >
            <Grid item xs={12} md={5}>
              <Box sx={{ py: 8, mt: 5, px: { xs: 3, md: 0 } }}>
                <Typography variant="h3" sx={{ fontSize: "42px", fontFamily: 'Montserrat' }} color="white" fontWeight="bold" gutterBottom>
                  Great Entrepreneurs
                  <br />
                  are Built Here
                </Typography>
                <Typography variant="body1" color="white">
                  The ecommerce industry is growing at an impressive rate, with opportunities for entrepreneurs who can take advantage of this trend.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} sx={{ zIndex: 99 }}>
              <Box sx={{ textAlign: { xs: "center", md: "right" }, width: { xs: "100%", sm: "60%", md: "80%" }, mx: "auto" }}>
                <img src={Images.mobile} alt="" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Container>

      </Box>

      {/* Best Features */}

      <Box textAlign={"center"} mb={5} mt={10}>
        {/* <Typography variant="body2" color="primary" fontWeight="bold">BEST FEATURES</Typography> */}
        <Typography variant="h4" color="initial" fontWeight="bold">Learn from the best trainers in the field</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ width: { xs: "80%", md: "50%" }, mx: "auto", fontSize: "14px", mt: 1 }}>The dream of running an ecommerce business has finally come true! Now, you can take your skills to the next level with this comprehensive certification program.</Typography>
      </Box>

      <Container>

        <Grid container spacing={2} alignItems="center" >
          <Grid item container rowGap={3} xs={12} md={3.5}>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#efdff7", mr: 1, maxWidth: "52px", height: "52px", px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.label}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  FBA PL</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">Get your needs catered as a new seller if you are trying to enter into Amazon.</Typography>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#c6e6fd", mr: 1, maxWidth: "52px", height: { xs: "50px", md: "50px" }, px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.wholesale}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  Amazon FBA Wholesale</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">You will have practical exposure to understand the end-to-end supply chain of amazon FBA wholesale.</Typography>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#efe3e3", mr: 1, maxWidth: "52px", height: { xs: "50px", md: "50px" }, px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.freelancer}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  Freelancing</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">Become an expert in freelancing on different platforms.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={Images.learningAnimate} alt=""
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item container rowGap={3} xs={12} md={3.5}>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#ddedea", mr: 1, maxWidth: "52px", height: { xs: "50px", md: "50px" }, px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.shopify}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  Shopify Dropshipping</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">Set up & design your store by implementing the processes involved in running a business on Shopify. </Typography>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#ffe0e3", mr: 1, maxWidth: "52px", height: { xs: "50px", md: "50px" }, px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.digital2}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  Digital Marketing Basics</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">It is a complete package for you to learn digital marketing and start to make money with it</Typography>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={{ xs: 2, md: 1.5 }} xs={12}>
              <Grid item xs={2} sm={1.8} md={2.5}>
                <Box sx={{ bgcolor: "#e2e8ff", mr: 1, maxWidth: "52px", height: { xs: "50px", md: "50px" }, px: 1, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "30px"
                    }}
                    image={Images.digital1}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item xs={10} sm={9.2} md={9.5}>
                <Typography variant="body1" fontWeight={"bold"} color="initial">
                  Digital Marketing Advanced</Typography>
                <Typography variant="body2" mt={1} lineHeight={2} color="textSecondary">Boost your digital marketing skills with an advanced digital marketing course. </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>

      {/* Training Courses */}
      <Box sx={{
        my: 2, mt: 8, mx: 'auto', width: '70%', display: 'flex',
        justifyContent: 'center', bgcolor: 'background.paper'
      }}>
        <Tabs
          value={scrollValue}
          onChange={handleScroll}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab
            label="All"
            className={clsx(classes.tabButton, { [classes.active]: isActive === null, })} onClick={() => { getTrainings(); setIsActive(null) }}
          />
          {category.map((item, index) => (
            <Tab
              label={item.Category}
              key={index} className={clsx(classes.tabButton, { [classes.active]: isActive === item.Id, })} onClick={() => { getTrainingsById(item.Id); setIsActive(item.Id) }}
              sx={{ mx: 1 }}
            />
          ))}
        </Tabs>
      </Box>

      <Container>
        <Grid container spacing={3}
          sx={{ mt: '50px', mb: '120px' }}
        >
          {trainings.length > 0 ? (
            <Fragment>
              {trainings.map((item, index) => (
                <Grid key={index} item xs={12} md={4} >
                  <Card
                    sx={{
                      borderRadius: '14px',
                      color: colors.white,
                      transition: "all 0.3s ease-in",
                      boxShadow: "0 0 20px #dbdbdb",
                      '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.05)'
                      }

                    }}>
                    <CardContent
                      sx={{ textDecoration: 'none', p: 0 }}
                    >
                      <Box
                        component={Link} to={`/training/${item.Id}`}>
                        <img
                          src={item.BannerImage} width='100%' height='230px' alt=""
                          style={{ borderRadius: '14px', backgroundPosition: 'center', margin: "auto" }} />
                      </Box>
                      <Typography variant='h5'
                        sx={{
                          textAlign: 'center', my: 2, color: colors.black
                        }}>{item.CourseName}</Typography>
                      <Typography variant='body2'
                        sx={{
                          textAlign: 'center',
                          color: colors.textPrimary,
                          px: 2,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          '-webkit-line-clamp': '3',
                          '-webkit-box-orient': 'vertical'
                        }}
                      >
                        {item.BriefOverview}
                      </Typography>
                      <Box sx={{ textAlign: 'center', mt: 2.5 }}>
                        <Button variant="contained"
                          endIcon={<LockOpen />}
                          onClick={() => { setOpenRegister(!openRegister); setCourseId(item.Id) }}
                          sx={{ color: colors.white, mr: 2, textDecoration: 'none', mb: 1.5 }}>Register</Button>
                        <Button variant="outlined"
                          component={Link} to={`/training/${item.Id}`}
                          endIcon={<ArrowForward />}
                          sx={{ color: colors.primary, textDecoration: 'none', mb: 1.5 }}>Know More</Button>
                      </Box>
                    </CardContent>

                  </Card>
                </Grid>
              ))}
            </Fragment>
          ) : (
            <Box sx={{ my: 2, width: 1, textAlign: "center" }}>
              {loader && <Loader />}
              {category.length === 0 && loader === false && <Typography variant="h6" >No Course Found</Typography>}
            </Box>
          )}

        </Grid>
      </Container>

      {/* Counts Banner */}
      <Box sx={{
        background: `linear-gradient( to right top,rgba(250, 84, 28), rgba(250, 84, 28,0.8)),url(${Images.hero})no-repeat top center / cover`,
        minHeight: 350,
        px: 5,
        py: { xs: 3, md: 0 },
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly"
      }}>
        <Container>
          <Grid container justifyContent={"center"} spacing={8}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h2" fontWeight="bold" color="white">5K</Typography>
                <Typography variant="body2" color="white">Students have already completed these courses</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h2" fontWeight="bold" color="white">96%</Typography>
                <Typography variant="body2" color="white">Satisfaction rate comes from our awesome students</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h2" fontWeight="bold" color="white">4.9</Typography>
                <Typography variant="body2" color="white">Average student ratings we have got all over the internet</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>


      </Box>

    </Layout>
  )
}

export default Learning