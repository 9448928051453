import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import AppRoutes from './routes/routes';
//import PrivateRoutes from './routes/PrivateRoutes';
import GoToTop from './hooks/scrollTop';
import useAuth from './hooks/useAuth';

// *Import Components
import { Toaster } from './components/UI/Toaster';
//import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
// import Login from './views/Session/Login/Login';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(250, 84, 28)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#707070',
      contrastText: '#fff',
    },
  },
  // typography: {
  //   allVariants: {
  //     color: '#262626',
  //   },
  fontFamily: [
    'Sans-serif',
  ].join(','),

});

function App() {

  const { user, verify } = useAuth();

  useEffect(() => {
    verify();
  })

  return (
    <BrowserRouter>

      {/* ========== Toaster ========== */}
      <Toaster />

      <ThemeProvider theme={theme}>

        {/* ========== Routes ========== */}
        <Routes>

          {/* ========== App Routes ========== */}
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}

          {/* <Route element={user ? <Navigate to="/" /> : <Outlet />} >
            <Route path="/login" element={<Login />} />
          </Route> */}

          {/* ========== Private Routes ========== */}
          {/* <Route element={user ? <Outlet /> : <Navigate to="/login" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route> */}

        </Routes>



        {/* ========== Go TO TOP ========== */}
        <GoToTop />

      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
