import { Navigate } from "react-router-dom";

// *Import Components
import { PageNotFound } from "app/components/NotFound/NotFound";
import Learning from "app/views/Learning/Learning";
import Training from "app/views/Courses/Training";


const AppRoutes = [
  {
    path: "/",
    component: <Learning />
  },
  {
    path: "*",
    component: <Navigate to="/404" />
  },
  {
    path: "/404",
    component: <PageNotFound />,
  },

  {
    path: "/learning",
    component: <Learning />,
  },
  {
    path: "/training/:id",
    component: <Training />,
  },


]

export default AppRoutes