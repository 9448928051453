const colors = {
  primary: 'rgb(250, 84, 28)',
  lightPrimary: 'rgba(250, 84, 28,0.2)',
  secondary: '#707070',
  secondaryLight: '#C2C2C2',
  textPrimary: '#637281',
  textSecondary: '#212836',
  lightWhite: 'rgba(255, 255, 255, 0.8)',
  white: '#fff',
  black: '#262626',
  lightGray: '#f5f5f5',
  antiqueWhite: '#FFE8E1',
  portlanOrange: '#ff572217',
  Rhino: '#344768',
  RhinoDark: '#2B374C',
  RhinoLight: '#8194B3',

};

export default colors;